<template>
  <v-app class="app">
    <v-main>
      <Metronome/>
    </v-main>
  </v-app>
</template>

<script>
import Metronome from './components/Metronome.vue'

export default {
  name: 'App',

  components: {
    Metronome,
  },

  data: () => ({
    //
  }),
}
</script>
<style scoped>

.app {
  background-color: #ebcc9e;
  background-image: url('./assets/background_sara.png');
  background-repeat: repeat-x;
  background-position: 95% 75%;
}

</style>
