<context-page>
  <v-card style="background-color:#a14747" class="background">
    <v-card-title class="title" align="center">
      <p>МЕТРОНОМ ЗА НАРОДНУ МУЗИКУ</p>
    </v-card-title>
    <v-divider class="line"/>
    <v-row>
      <v-col align="center">
        <input class="radioBeat" type="radio" id="beat" value="beat" v-model="typeBeats" :disabled="activeTimer"/>
        <label class="labelBeat" for="beat">Метроном</label>

        <input class="radioGoc" type="radio" id="goc" value="goc" v-model="typeBeats" :disabled="activeTimer"/>
        <label class="labelGoc" for="goc">Тапан</label>

        <v-select class="selectMeasure" v-model="measure" :items="measureItems" :disabled="activeTimer" bg-color="#ebcc9e"></v-select>
        <v-select class="selectSpeed" v-model="speed" :items="speedItems" :disabled="activeTimer" bg-color="#ebcc9e"></v-select>

        <v-btn class="start" size="small" variant="flat" @click="start()" :disabled="activeTimer">СТАРТ</v-btn>
        <v-btn class="stop" size="small" variant="flat" @click="stop()" :disabled="!activeTimer">СТОП</v-btn>
      </v-col>
    </v-row>
  </v-card>
</context-page>