<template src="./metronome.html" />

<script>

export default {
  name: 'MetronomeApp',
  props: {
    msg: String
  },

  data() {
    return{
      activeTimer: false,
      audio: null,
      gocFirst: null,
      gocSecond: null,
      typeBeats: null,
      firstBeat: null,
      secondBeat: null,
      speedItems: [
        30,
        35,
        40,
        45,
        50,
        55,
        60,
        65,
        70,
        75,
        80,
        85,
        90,
        95,
        100,
        105,
        110,
        115,
        120,
        125,
        130,
        135,
        140,
        145,
        150,
        155,
        160,
        165,
        170,
        175,
        180,
        185,
        190,
        195,
        200,
        205,
        210,
        215,
        220,
        225,
        230,
        235,
        240,
        245,
        250,
        255,
        260,
        265,
        270,
        275,
        280,
        285,
        290,
        295,
        300
      ],
      speed: 60,
      timerCounter: 1000,
      measure: '1/4',
      counterBeat: 0,
      measureItems: [
        '1/4',
        '7/8 (на прву)',
        '7/8 (на средњу)',
        '7/8 (на трећу)',
        'румба (3 ударца)',
        'румба (4 ударца)',
        '9/8',
        '5/8',
        '11/8 (у средини)',
        '12/8',
        'бегин',
        'ориентал'
      ]
    }
  },
  mounted () {
    this.audio = new Audio(require('../assets/metronome_tick.mp3'))
    this.audio_second = new Audio(require('../assets/metronome_tick_2.mp3'))
    this.gocFirst = new Audio(require('../assets/goc_first.mp3'))
    this.gocSecond = new Audio(require('../assets/goc_second.mp3'))
    this.gocThird = new Audio(require('../assets/goc_first_2.mp3'))
    this.gocForth = new Audio(require('../assets/goc_second_2.mp3'))
    this.gocFifth = new Audio(require('../assets/goc_second.mp3'))
    this.typeBeats = 'beat'
  },
  methods: {
    timer () {
      if (this.activeTimer) {
          setTimeout(() => {
            if (this.measure === '1/4') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 1) this.gocSecond.play()
                if (this.counterBeat === 2) this.gocThird.play()
                if (this.counterBeat === 3) this.gocForth.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 4) this.counterBeat = 0
            }
            if (this.measure === '7/8 (на прву)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 2) this.gocSecond.play()
                if (this.counterBeat === 3) this.gocFirst.play()
                if (this.counterBeat === 5) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 3) this.tick()
                if (this.counterBeat === 5) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 7) this.counterBeat = 0
            } 
            if (this.measure === '7/8 (на средњу)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocSecond.play()
                if (this.counterBeat === 2) this.gocFirst.play()
                if (this.counterBeat === 4) this.gocSecond.play()
                if (this.counterBeat === 5) this.gocFirst.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
                if (this.counterBeat === 5) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 7) this.counterBeat = 0
            } 
            if (this.measure === '7/8 (на трећу)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 2) this.gocSecond.play()
                if (this.counterBeat === 4) this.gocFirst.play()
                if (this.counterBeat === 6) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
                if (this.counterBeat === 4) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 7) this.counterBeat = 0
            } 
            if (this.measure === 'румба (3 ударца)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 3) this.gocSecond.play()
                if (this.counterBeat === 6) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 3) this.tick()
                if (this.counterBeat === 6) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 8) this.counterBeat = 0
            } 
            if (this.measure === 'румба (4 ударца)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 3) this.gocSecond.play()
                if (this.counterBeat === 4) this.gocFirst.play()
                if (this.counterBeat === 6) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 3) this.tick()
                if (this.counterBeat === 4) this.tick()
                if (this.counterBeat === 6) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 8) this.counterBeat = 0
            } 
            if (this.measure === '9/8') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 1) this.gocSecond.play()
                if (this.counterBeat === 2) this.gocThird.play()
                if (this.counterBeat === 3) this.gocForth.play()
                if (this.counterBeat === 4) this.gocFirst.play()
                if (this.counterBeat === 5) this.gocSecond.play()
                if (this.counterBeat === 6) this.gocThird.play()
                if (this.counterBeat === 7) this.gocForth.play()
                if (this.counterBeat === 8) this.gocFifth.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
                if (this.counterBeat === 4) this.tick()
                if (this.counterBeat === 6) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 9) this.counterBeat = 0
            } 
            if (this.measure === '5/8') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 1) this.gocSecond.play()
                if (this.counterBeat === 2) this.gocThird.play()
                if (this.counterBeat === 3) this.gocForth.play()
                if (this.counterBeat === 4) this.gocFifth.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 5) this.counterBeat = 0
            } 
            if (this.measure === '11/8 (у средини)') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 2) this.gocSecond.play()
                if (this.counterBeat === 4) this.gocFirst.play()
                if (this.counterBeat === 6) this.gocForth.play()
                if (this.counterBeat === 7) this.gocThird.play()
                if (this.counterBeat === 9) this.gocForth.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
                if (this.counterBeat === 4) this.tick()
                if (this.counterBeat === 7) this.tick()
                if (this.counterBeat === 9) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 11) this.counterBeat = 0
            } 
            if (this.measure === '12/8') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 1) this.gocSecond.play()
                if (this.counterBeat === 2) this.gocThird.play()
                if (this.counterBeat === 3) this.gocForth.play()
                if (this.counterBeat === 4) this.gocFifth.play()
                if (this.counterBeat === 5) this.gocFirst.play()
                if (this.counterBeat === 6) this.gocSecond.play()
                if (this.counterBeat === 7) this.gocThird.play()
                if (this.counterBeat === 8) this.gocForth.play()
                if (this.counterBeat === 9) this.gocFirst.play()
                if (this.counterBeat === 10) this.gocSecond.play()
                if (this.counterBeat === 11) this.gocForth.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 2) this.tick()
                if (this.counterBeat === 5) this.tick()
                if (this.counterBeat === 7) this.tick()
                if (this.counterBeat === 9) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 12) this.counterBeat = 0
            } 
            if (this.measure === 'бегин') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 3) this.gocSecond.play()
                if (this.counterBeat === 6) this.gocForth.play()
                if (this.counterBeat === 8) this.gocFirst.play()
                if (this.counterBeat === 12) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 3) this.tick()
                if (this.counterBeat === 6) this.tick()
                if (this.counterBeat === 8) this.tick()
                if (this.counterBeat === 12) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 16) this.counterBeat = 0
            } 
            if (this.measure === 'ориентал') {
              if (this.typeBeats === 'goc') {
                if (this.counterBeat === 0) this.gocFirst.play()
                if (this.counterBeat === 3) this.gocSecond.play()
                if (this.counterBeat === 6) this.gocForth.play()
                if (this.counterBeat === 8) this.gocThird.play()
                if (this.counterBeat === 10) this.gocFifth.play()
                if (this.counterBeat === 12) this.gocSecond.play()
              }
              if (this.typeBeats === 'beat') {
                if (this.counterBeat === 0) this.tick()
                if (this.counterBeat === 3) this.tick()
                if (this.counterBeat === 6) this.tick()
                if (this.counterBeat === 8) this.tick()
                if (this.counterBeat === 10) this.tick()
                if (this.counterBeat === 12) this.tick()
              }
              this.counterBeat++
              if (this.counterBeat === 16) this.counterBeat = 0
            }

            this.timer()
          }, this.timerCounter)
      }
    },
    goc14 () {
      if (this.activeTimer) {
          setTimeout(() => {
            if (this.counterBeat === 0) this.gocFirst.play()
            if (this.counterBeat === 2) this.gocSecond.play()
            if (this.counterBeat === 4) this.gocThird.play()
            if (this.counterBeat === 6) this.gocForth.play()
            this.counterBeat++
            if (this.counterBeat === 8) this.counterBeat = 0
            this.goc14()
          }, this.timerCounter)
      }
    },
    beat14 () {
      if (this.activeTimer) {
          setTimeout(() => {
            if (this.counterBeat === 0) this.audio.play()
            if (this.counterBeat === 4) this.audio_second.play()
            this.counterBeat++
            if (this.counterBeat === 8) this.counterBeat = 0
            this.beat14()
          }, this.timerCounter)
      }
    },
    goc98(){
      if (this.activeTimer) {
          setTimeout(() => {
           if (this.counterBeat === 0) this.gocFirst.play()
           if (this.counterBeat === 2) this.gocSecond.play()
           if (this.counterBeat === 4) this.gocThird.play()
           if (this.counterBeat === 6) this.gocForth.play()
           if (this.counterBeat === 8) this.gocFirst.play()
           if (this.counterBeat === 10) this.gocSecond.play()
           if (this.counterBeat === 12) this.gocThird.play()
           if (this.counterBeat === 14) this.gocForth.play()
           if (this.counterBeat === 16) this.gocFifth.play()
           this.counterBeat++
           if (this.counterBeat === 18) this.counterBeat = 0
           this.goc98()
          }, this.timerCounter)
      }
      
    },
    beat98() {
      if (this.activeTimer) {
        setTimeout(() => {
          if (this.counterBeat === 0) this.audio.play()
          if (this.counterBeat === 4) this.audio_second.play()
          if (this.counterBeat === 8) this.audio.play()
          if (this.counterBeat === 12) this.audio_second.play()
          this.counterBeat++
        if (this.counterBeat === 18) this.counterBeat = 0
          this.beat98()
        }, this.timerCounter)
      }      
    },
    start() {
      this.counterBeat = 0
      this.timerCounter = 1000/(this.speed/60)
      if (this.measure === '1/4' || this.measure === '7/8 (на прву)' || this.measure === '7/8 (на средњу)' || this.measure === '7/8 (на трећу)'
      || this.measure === 'румба (3 ударца)' || this.measure === 'румба (4 ударца)' || this.measure === '9/8'
      || this.measure === '5/8' || this.measure === '11/8 (у средини)' || this.measure === '12/8') {
         this.timerCounter = 1000/(this.speed/60)/4
      }
      if (this.measure === 'бегин' || this.measure === 'ориентал'){
        this.timerCounter = 1000/(this.speed/60)/4
      }

      this.activeTimer = true
      if (this.measure === '1/4') {
        if (this.typeBeats === 'goc') {
          this.goc14()  
        }
        if (this.typeBeats === 'beat') {
          this.beat14()
        }
      }
      if (this.measure === '9/8') {
        if (this.typeBeats === 'goc') {
          this.goc98()
        }
        if (this.typeBeats === 'beat') {
          this.beat98()
        }
      } 
      //this.timer()
    },
    stop() {
      this.activeTimer = false
    },
    tick() {
      this.audio.play()
    }

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.selectSpeed {
      max-width: 200px;
      max-height: 60px;
      font-size: 11px;
      margin: 5px 0px 10px 0px;
}
.selectMeasure {
      max-width: 200px;
      max-height: 60px;
      font-size: 11px;
      margin: 20px 0px 5px 0px;
}
.title {
      margin-top: 20px;
      margin-bottom: 7px;
      color: #ebcc9e;
}
.line {
      border: 1px solid white;
      margin-bottom: 10px;
}
.radioBeat {
      margin-right: 5px;
      accent-color: #13456a;
}
.labelBeat {
      color: #ebcc9e;
      margin-right: 15px;
      font-size: 17px;
}
.radioGoc {
      margin-right: 5px;
      accent-color: #13456a;
}
.labelGoc {
      color: #ebcc9e;
      margin-right: 15px;
      font-size: 17px;
}
.start{
      width: 95px;
      background-color: #13456a;
      color: #ebcc9e;
      margin-top: 3px;
      margin-bottom: 10px;
}
.stop{
      width: 95px;
      background-color: #13456a;
      color: #ebcc9e;
      margin-top: 3px;
      margin-bottom: 10px;
}

</style>
